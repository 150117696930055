import React from "react";

import Router from "next/router";
import { CymbalInput } from "../../shared/components/cymbalInput";
import { LoadingButton } from "../../shared/components/loadingButton";
import { Organization } from "../../shared/models/organization";
import { OrganizationInviteInfo } from "../../shared/models/organizationInviteInfo";
import { UserSessionInfo } from "../../shared/models/userSessionInfo";
import { AppContext } from "../../shared/state/appContext";
import { BasePageContext } from "../../shared/state/basePageContext";
import { makeStyles } from "../../shared/styles/makeStyles";
import { apiGet, apiPatch, apiPost } from "../../shared/util/api";
import { getClientOrganizationId, setClientSessionToken } from "../../shared/util/cookies";
import { isValidEmail } from "../../shared/util/email";
import { handleErrorToast } from "../../shared/util/toast";
import { createUrl } from "../../shared/util/url";

const useStyles = makeStyles()((theme) => ({
    title: {
        fontSize: "1.5rem",
        marginBottom: theme.spacing(2),
    },
    fullNameInput: {
        marginBottom: theme.spacing(2),
    },
    emailInput: {
        marginBottom: theme.spacing(3),
    },
    agreementSection: {
        fontSize: "0.875rem",
        marginBottom: theme.spacing(3),
        textAlign: "center",
    },
    link: {
        color: theme.palette.linkBlue,
    },
}));

interface Props {
    className?: string;
    userSessionInfo: UserSessionInfo;
    destination: string | undefined;
    organizationInviteInfo: OrganizationInviteInfo | undefined;
    exitUrl: string | null;
}

export const CompleteSignUpFlow: React.FC<Props> = React.memo(
    ({ className, userSessionInfo, destination, organizationInviteInfo, exitUrl }) => {
        const { classes } = useStyles();
        const { resetUser, resetOrganization, resetOrganizations } = React.useContext(AppContext);
        const { errorToast } = React.useContext(BasePageContext);

        const { user, sessionToken } = userSessionInfo;

        const [fullName, setFullName] = React.useState(userSessionInfo.user.full_name);
        const [email, setEmail] = React.useState(userSessionInfo.user.email);

        const [showNameError, setShowNameError] = React.useState(false);
        const [showEmailError, setShowEmailError] = React.useState(false);
        const [isUpdatingInfo, setIsUpdatingInfo] = React.useState(false);

        const onSubmit = React.useCallback(async () => {
            if (!fullName) {
                setShowNameError(true);
                if (!isValidEmail(email)) {
                    setShowEmailError(true);
                }
                return;
            }

            if (!isValidEmail(email)) {
                setShowEmailError(true);
                return;
            }

            setIsUpdatingInfo(true);
            try {
                await apiPatch("/user", {
                    body: { email, full_name: fullName },
                    sessionToken,
                });

                let organization: Organization | undefined;
                let joined = false;
                if (organizationInviteInfo) {
                    try {
                        await apiPost("/org/member", {
                            sessionToken,
                            body: {
                                invite_secret: organizationInviteInfo.inviteSecret,
                            },
                            shouldAlert: (status) => status !== 409,
                        });

                        organization = organizationInviteInfo.organization;
                        joined = true;
                    } catch (error: any) {
                        if (error?.status !== 409) {
                            handleErrorToast({ error, message: "Failed to join organization", errorToast });
                            setIsUpdatingInfo(false);
                            return;
                        }
                    }
                }

                const organizations = await apiGet<Organization[]>("/orgs", { sessionToken }).catch(
                    () => [] as Organization[],
                );

                resetUser(user);
                setClientSessionToken(sessionToken);
                if (organizations.length === 0) {
                    Router.push(createUrl("/onboarding", !exitUrl ? {} : { exit_url: exitUrl }));
                    return;
                }

                let organizationToSet: Organization;
                if (!organization) {
                    const orgIdFromCookie = getClientOrganizationId();
                    if (orgIdFromCookie === undefined) {
                        organizationToSet = organizations[0];
                    } else {
                        const filteredOrgs = organizations.filter((org) => org.id === orgIdFromCookie);

                        if (filteredOrgs.length === 0) {
                            organizationToSet = organizations[0];
                        }

                        organizationToSet = filteredOrgs[0];
                    }
                } else {
                    organizationToSet = organization;
                }

                resetOrganization(organizationToSet);
                resetOrganizations(organizations);

                const fallbackDestination =
                    organizationToSet.saved_onboarding_step !== "finished" ? "/onboarding" : "/events";

                Router.push(
                    destination ??
                        createUrl(fallbackDestination, {
                            ...(joined && organizationInviteInfo && { joined: organizationInviteInfo.inviteSecret }),
                            ...(exitUrl && { exit_url: exitUrl }),
                        }),
                );
            } catch (error: any) {
                setIsUpdatingInfo(false);
                handleErrorToast({ error, message: "Failed to save email and full name", errorToast });
            }
        }, [
            fullName,
            email,
            sessionToken,
            organizationInviteInfo,
            user,
            destination,
            exitUrl,
            resetUser,
            resetOrganization,
            resetOrganizations,
            errorToast,
        ]);

        return (
            <div className={className}>
                <div className={classes.title}>Finish signing up</div>
                <CymbalInput
                    className={classes.fullNameInput}
                    label="Full name"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            onSubmit();
                        }
                    }}
                    isError={showNameError && !fullName}
                    errorText="Enter your full name"
                    fullWidth
                />
                <CymbalInput
                    className={classes.emailInput}
                    label="Email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            onSubmit();
                        }
                    }}
                    isError={showEmailError && !isValidEmail(email)}
                    errorText="Enter a valid email address"
                    fullWidth
                />

                <div className={classes.agreementSection}>
                    By selecting &apos;Continue&apos;, I agree to Cymbal&apos;s{" "}
                    <a
                        className={classes.link}
                        href="https://cymbal.co/disclosures/terms"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Terms of Use
                    </a>
                    ,{" "}
                    <a
                        className={classes.link}
                        href="https://cymbal.co/disclosures/privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Privacy Policy
                    </a>
                    , and receiving text messages and emails from Cymbal. Message frequency may vary.
                </div>
                <LoadingButton variant="contained" loading={isUpdatingInfo} fullWidth onClick={onSubmit}>
                    Continue
                </LoadingButton>
            </div>
        );
    },
);
